import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import MVPSportsClubContainer from "../containers/MVPSportsClubContainer"

const MVPSportsClub = (props) => (
  <Layout 
  pageInfo={{ pageName: "MVP Sports Club" }} 
  styleClass="home-page" 
  location={'/mvpsportsclub/'}>
    <SEO title="MVP Sports Club" />
    <MVPSportsClubContainer />
  </Layout>
)

export default MVPSportsClub
