import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import BannerImage from "../components/elements/BannerImage"
import banner from "../images/slicing/home/mvpsports.jpg"
class MVPSportsClubContainer extends Component {
  render() {
    return (
      <>
        <BannerImage
          activeTab="Case Study"
          bannerImage={banner}
          text="MVP Sports Clubs is a health facility management company with operations in West Michigan."
        />
        <Container fluid>
          <Container className="py-5 border-bottom">
            <Row>
              <Col>
                <h3 className="font-weight-bold">
                  <span
                    style={{
                      borderBottom: "2px",
                      borderBottomColor: "#eebb6b",
                      borderBottomStyle: "solid",
                    }}
                  >
                    The
                  </span>{" "}
                  Customer
                </h3>
                <p className="industries-paragrapgh pt-3">
                  MVP Sports Clubs is a health facility management company with
                  operations in West Michigan. MVP Sports Clubs represents
                  quality and success in health and recreation in all facets of
                  the community.
                </p>
                <p className="industries-paragrapgh pt-3">
                  During covid when all health and sports facilities were
                  closed, it became imperative for MVP to identify ways to keep
                  the members engaged, revenue protected and continue to provide
                  value to the ecosystem.
                </p>
              </Col>
              <Col>
                <h3 className="font-weight-bold">
                  <span
                    style={{
                      borderBottom: "2px",
                      borderBottomColor: "#eebb6b",
                      borderBottomStyle: "solid",
                    }}
                  >
                    Key
                  </span>{" "}
                  Information
                </h3>
                <Container className="pt-3">
                  <Row>
                    <Col className="standard-background mt-2 p-3">
                      <h3>Platform Scale</h3>
                      <p>
                        Mobile responsive Web Platform, Hybrid ReactNative based
                        Mobile app for customer experience, engagement and
                        customer operations for all health and sports facilities
                        under brand MVP.
                      </p>
                    </Col>
                    <Col className="standard-background ml-0 ml-md-2 mt-2 p-3">
                      <h3>Technology</h3>
                      <p>Microsoft .Net MVC, AngularJS, ReactNative</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="standard-background mt-2 p-3">
                      <h3>GTEN Role</h3>
                      <p>Engineering Partner</p>
                    </Col>
                    <Col className="standard-background ml-0 ml-md-2 mt-2 p-3">
                      <h3>Client Growth</h3>
                      <p>
                        Customer Experience Rating improved to 4.9/5 <br />
                        Customer Enrolment & Onbaording time reduced by 80%
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className="py-5 border-bottom">
            <Row>
              <Col md="6" sm="12">
                <h3 className="font-weight-bold">
                  <span
                    style={{
                      borderBottom: "2px",
                      borderBottomColor: "#eebb6b",
                      borderBottomStyle: "solid",
                    }}
                  >
                    Key
                  </span>{" "}
                  Challenge
                </h3>
                <p className="industries-paragrapgh pt-3">
                  • Technology being used was developed over a period of few
                  years. Development done out of necessity instead of a planned
                  product development always has issues to scale and high cost
                  of maintenance.
                </p>
                <p className="industries-paragrapgh">
                  • MVP web application was developed over years with different
                  developers working on it also had lack of coding standards
                  which contributed to high maintenance and fragile code base.
                </p>
                <p className="industries-paragrapgh">
                  • Mobile apps were developed but not maintained as per latest
                  releases from iOS and Android leading to outdated libraries,
                  lack of support and broken features.
                </p>
              </Col>
              <Col md="6" sm="12">
                <h3 className="font-weight-bold">
                  <span
                    style={{
                      borderBottom: "2px",
                      borderBottomColor: "#eebb6b",
                      borderBottomStyle: "solid",
                    }}
                  >
                    GTEN
                  </span>{" "}
                  Solution
                </h3>
                <p className="industries-paragrapgh pt-3">
                  Our solution was based on bringing in best engineering
                  practices and rigour
                </p>
                <p className="industries-paragrapgh">
                  • Improve customer experience by updated front-end of both web
                  and mobile applications as per latest material UI design
                </p>
                <p className="industries-paragrapgh">
                  • Optimize database schema to remove tables, procedures not
                  used to improve application performance and response time
                </p>
                <p className="industries-paragrapgh">
                  • Updated and redeveloped critical user facing features of
                  mobile app for both iOS and android
                </p>
                <p className="industries-paragrapgh">
                  • Developed new payment flow for membership taking it from
                  offline in the club to online from web app and mobile app
                </p>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row>
              <Col md="6" sm="12">
                <Container fluid>
                  <Row>
                    <Col>
                      <h3 className="font-weight-bold">
                        <span
                          style={{
                            borderBottom: "2px",
                            borderBottomColor: "#eebb6b",
                            borderBottomStyle: "solid",
                          }}
                        >
                          Benefits
                        </span>{" "}
                        Achieved
                      </h3>
                      <p className="industries-paragrapgh pt-3">
                        Following benefits were delivered to customer
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>Increased revenue by 160%</li>
                        <li>Improved customer engagement by 80%</li>
                        <li>CSAT went up from 3.7 to 4.9</li>
                        <li>
                          Geotagging of facilities with integration with
                          membership records to improve customer visit to
                          facility by 70%
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md="6" sm="12">
                <Container fluid>
                  <Row>
                    <Col>
                      <h3 className="font-weight-bold">
                        <span
                          style={{
                            borderBottom: "2px",
                            borderBottomColor: "#eebb6b",
                            borderBottomStyle: "solid",
                          }}
                        >
                          Customer
                        </span>{" "}
                        Voice
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        We are very grateful to GTEN for their ability to fill
                        in the gaps with their technical expertise. As we’ve
                        navigated the difficulties of staffing shortages and
                        business pivots, GTEN has provided us the assets we’ve
                        needed to maintain business continuity. They have
                        provided talent from the whole spectrum of our needs and
                        to fit our budget for business critical projects,
                        whether we needed front end or back end only or a
                        database developer or a full stack developer, they’ve
                        been able to help us succeed. The ability to ramp up and
                        ramp down the assets we’ve needed with the skills
                        demanded as we’ve needed them has allowed us to be agile
                        during a period of disruption and change in our
                        industry.
                      </p>
                      <p>
                        <b> Mike Lodes</b> <br />
                        Chief Marketing & Technology Officer
                        <br />
                        MVP Sports Clubs
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <hr />
        </Container>
      </>
    )
  }
}

export default MVPSportsClubContainer
